import { Content } from '@models/content/content';
import { Inject, Injectable, Optional } from '@angular/core';
import { EditorNode } from '@models/employee-handbook/editor-node';
import { ContentHandlerInterface } from '@core/content-handlers/content-handler.interface';
import { MarkHandlerInterface } from '@core/content-handlers/mark-handler.interface';
import { CONTENT_HANDLERS_INJECTION_TOKEN } from '@core/injection-tokens/content-handlers.injection-token';
import { MARK_HANDLERS_INJECTION_TOKEN } from '@core/injection-tokens/mark-handlers.injection-token';


@Injectable({
  providedIn: 'root',
})
export class DomBuilderService {
  constructor(
    @Inject(CONTENT_HANDLERS_INJECTION_TOKEN)
    @Optional()
    private _contentHandlers?: ContentHandlerInterface[],
    @Inject(MARK_HANDLERS_INJECTION_TOKEN)
    @Optional()
    private _markHandlers?: MarkHandlerInterface[],
  ) {
  }

  static prioritySorterFunction = (a: ContentHandlerInterface | MarkHandlerInterface, b: ContentHandlerInterface | MarkHandlerInterface) => {
    if (a.getPriority() === b.getPriority()) {
      return 0;
    }

    return a.getPriority() > b.getPriority() ? 1 : -1;
  };

  static getSupportedContentHandler(type: string, contentHandlers: ContentHandlerInterface[]): ContentHandlerInterface {
    let ch = null;

    contentHandlers.sort(DomBuilderService.prioritySorterFunction).forEach((h) => {
      if (h.supports(type)) {
        ch = h;
      }
    });

    if (null === ch) {
      throw new Error(`No content handler was found for type ${type}`);
    }

    return ch;
  }

  static getSupportedMarkHandler(type: string, markHandlers: MarkHandlerInterface[]): MarkHandlerInterface {
    let mh = null;
    markHandlers.sort(DomBuilderService.prioritySorterFunction).forEach((h) => {
      if (h.supports(type)) {
        mh = h;
      }
    });

    if (null !== mh) {
      return mh;
    }

    throw new Error(`No mark handler was found for type ${type}`);
  }

  makeContent(content: Content, editorNodes?: EditorNode[]): any {
    const replacedContent = this.replaceRelationBlockContent(content, editorNodes);

    let typeName = replacedContent.type;

    if (Object.hasOwn(replacedContent, '__typename')) {
      typeName = replacedContent.__typename;
    }

    const ch = DomBuilderService.getSupportedContentHandler(typeName, this._contentHandlers || []);

    if (null !== ch) {
      return ch.handle(replacedContent, this._contentHandlers || [], this._markHandlers || []);
    }

    throw new Error(`No content handler available to handle content of type ${typeName}`);
  }

  replaceRelationBlockContent(content: Content, editorNodes?: EditorNode[]): any {
    switch (content.type) {
      case 'bulletList':
        content.content?.forEach((li) => {
          li.content?.forEach((c, index) => {
            li.content[index] = this.replaceRelationBlockContent(c, editorNodes);
          });
        });
        return content;
      case 'relation-block':
        let replacement = null;
        editorNodes?.forEach((en) => {
          if (content.attrs.collection === en.collection && content.attrs.id === en.id) {
            replacement = en.item;
          }
        });

        return null !== replacement ? replacement : content;
      case 'heading':
      case 'paragraph':
      default:
        return content;
    }
  }
}
